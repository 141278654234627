import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Container from "react-bootstrap/Container";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';


function Step1({ onSubmit, prevStep }) {
    const [formData, setFormData] = useState({});

    function handleSubmit(event) {
        event.preventDefault();
        onSubmit(formData);
    }

    function handleInputChange(event) {
        setFormData({
            ...formData,
            [event.target.name]: event.target.value
        });
    }

    const contentStyle = {
    
        borderColor: '#4C6C84',
        color: '#fff',
        padding: '12px 24px',
        borderRadius: '0px 0px  8px  8px',
        paddingTop: '20px',
        fontSize: '16px',
        overflow: 'auto',
        width: '740px'
    
    }

    return (
        <div>
            <Row> <h1 style={{ marginBottom: '40px' }}>Create a new simulation</h1></Row>
            <div style={{ border: "1.5px solid #fff", borderRadius:"6px" }}>
            <Row>
                <ul className="steps">
                <li className="is-active">1: Name</li>
                <li>2: Environments</li>
                <li>3: Roles</li>
                <li>4: Connect roles to environments</li>
                <li>5: Save simulation</li>
                </ul>
            </Row>
            <Row>
                <form onSubmit={handleSubmit} style={contentStyle} >
                    <Container>
                        <Row>
                            <label>Give a name to the simulation: </label>
                            <input className="name-input" type="text" name="name" onChange={handleInputChange} required />
                        </Row>
                        <Row>
                            <Col></Col>
                            <Col className="text-end" style={{marginTop: '30px'} }><Button type="submit">Next step{' >'} </Button></Col>
                        </Row>
                    </Container>
                </form>
            </Row>
            </div>

        </div>

    );
}

export default Step1;
