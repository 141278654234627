import React, { Component } from "react";
import {
  Collapse,
  Navbar,
  NavbarBrand,
  NavbarToggler,
  NavItem,
    NavLink,
  Button,
} from "reactstrap";
import { Link } from "react-router-dom";
import "./NavMenu.css";

export class NavMenu extends Component {
  static displayName = NavMenu.name;

  constructor(props) {
    super(props);

    this.toggleNavbar = this.toggleNavbar.bind(this);
    this.state = {
      collapsed: true,
    };
  }

  toggleNavbar() {
    this.setState({
      collapsed: !this.state.collapsed,
    });
    }

    // Function to clear local storage
    clearLocalStorage() {
        localStorage.clear();

        window.location.href = "/"; // Navigate to the index
    }

    render() {

        const isPersonnelCredentialsPresent = localStorage.getItem("personnelCredentials");
    return (
      <header>
        <Navbar
          className="navbar-expand-sm navbar-toggleable-sm box-shadow mb-3"
          container
          light
            >
            
           
                <NavbarBrand tag={Link} to="/">
                  RCR Simulation lab     
                </NavbarBrand>
               
               

                {isPersonnelCredentialsPresent && ( 
                    <Button style={{ backgroundColor: "#c24502", borderColor: "#ab3e03" }} onClick={this.clearLocalStorage}>
                        Log out
                    </Button>
                )}
               
          <NavbarToggler onClick={this.toggleNavbar} className="mr-2" />
          <Collapse
            className="d-sm-inline-flex flex-sm-row-reverse"
            isOpen={!this.state.collapsed}
            navbar
                >
          
            <ul className="navbar-nav flex-grow">
              <NavItem>
                <NavLink tag={Link} className="text-light" to="/">
                  Run simulation
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  tag={Link}
                  className="text-light"
                  to="/prepare-simulation"
                >
                  Prepare simulation
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink tag={Link} className="text-light" to="/participants">
                  Participants
                            </NavLink>

              </NavItem>
            </ul>
          </Collapse>
        </Navbar>
      </header>
    );
  }
}
