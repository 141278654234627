import React, { useState } from "react";
import Step1 from "./NewStep1";
import Step2 from "./NewStep2";
import Step3 from "./NewStep3";
import Step4 from './NewStep4';
import Button from 'react-bootstrap/Button';

function MultiStepForm(step0) {
    const [step, setStep] = useState(1);
    const [stepOneData, setStepOneData] = useState({});
    const [stepTwoData, setStepTwoData] = useState({});
    const [stepThreeData, setStepThreeData] = useState({});
    const [stepFourData, setStepFourData] = useState([]);

    function handleClickNewSimulation() {
        setStep(1)
    }

    function handleStepOneSubmit(data) {
        setStepOneData(data);
        setStep(2);
    }

    function handleStepTwoSubmit(data) {
        setStepTwoData(data);
        setStep(3);
    }

    function handleStepThreeSubmit(data) {
        setStepThreeData(data);
        setStep(4);
    }

    function handleStepThreeAdd(data) {
        setStepThreeData(data);
    }

    function handleStepThreeDelete(data) {
        setStepThreeData(data);
    }

    const handleStepFourSubmit = (items) => {
        setStepFourData(items);
    };

    function handleGoBack() {
        setStep(step - 1);
    }

    function handleGoToStart() {
        setStep(step - 4);
    }
    
    function handleSubmit() {
        const url = '/Step4';

        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(stepFourData)
        })
            .then(response => {
                if (response.ok) {
                    console.log('Data was successfully posted!');

                } else {
                    console.log('Something went wrong!');
                }
            })
            .catch(error => {
                console.error('Network error:', error);
            });
};

    const steps = [
        {
            component: <div className="prepare-simulation-box" onClick={handleClickNewSimulation}>Prepare a new simulation from scratch</div>, title: 'Step 0'
        },
        {
            component: <Step1 onSubmit={handleStepOneSubmit} prevStep={handleGoBack} />, title: 'Step 1'
        },
        {
            component: <Step2 onSubmit={handleStepTwoSubmit} simulation={stepOneData} scenarios={stepTwoData} prevStep={handleGoBack} />, title: 'Step 2'
        },
        {
            component: <Step3 onSubmit={handleStepThreeSubmit} deleteRole={handleStepThreeDelete} addRole={handleStepThreeAdd} simulation={stepOneData} roles={stepThreeData} prevStep={handleGoBack} />, title: 'Step 3'
        },
        {
            component: <Step4 onSaveItems={handleStepFourSubmit} scenarios={stepTwoData} roles={stepThreeData} simulation={stepOneData} onSubmit={handleSubmit} prevStep={handleGoBack} step0={step0} />, title: 'Step 4'
        },
    
    ];

    const containerStyle = {
        Width: '85%',
        margin: '10%',
        borderRadius: '8px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        
    };

    return (
        <div style={containerStyle} >
        
            {steps[step - 0].component}
  
        </div>
    );
}

export default MultiStepForm;
