import React from 'react';
import { useLocation } from 'react-router-dom';
import { NavMenu } from './NavMenu';
import { NavMenuParticipants } from './NavMenuParticipants';
import Cnt from "react-bootstrap/Container";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const Layout = ({ children }) => {
    const location = useLocation();
    const isParticipantsPage = location.pathname.includes('/participants');

    return (
        <div>
            {isParticipantsPage ? <NavMenuParticipants /> : <NavMenu />}
            <Cnt fluid tag="main">
                <Row className="justify-content-center">
                    <Col xs={12} className="text-center">
                        {children}
                    </Col>
                </Row>
            </Cnt>
        </div>
    );
};

export default Layout;


