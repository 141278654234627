import React, { useState } from 'react';

function MessageInput(props) {

    return (
        <div style={{ position: 'relative', marginBottom: '20px', marginTop: "20px" }}>
            <label
                style={{
                    
                    color: '#fff'
                }}
            >
                Message or role to send to participant(s):
            </label>
            <textarea
                id="my-input"
                className="message-box"
                type="text"

                style={{
                    width: '100%',
                    padding: '10px',
                    border: 'none',
                    borderRadius: '6px',
                    color: 'black',
                    fontSize: '16px',
                    fontWeight:'600',
                    transition: 'all 0.3s ease-in-out',
                    boxShadow: '0 0 0 2px #7395AE',
                    backgroundColor: '#f8f8f8',
                    outline: 'none',
                }}
                value={props.message}
                onChange={e => props.setMessage(e.target.value)}
            />
        </div>
    );
}

export default MessageInput;



