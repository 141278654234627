import React, { useState } from "react";
import DeleteStep1 from "./DeleteStep1";

function DeleteSimulation() {
    const [step, setStep] = useState(1);

    function handleClickDeleteSimulation() {
        setStep(1);
    }
   
    function handleGoBack() {
        setStep(step - 1);
    }

    const steps = [
        {
            component: <div className="prepare-simulation-box" onClick={handleClickDeleteSimulation}>Delete simulations</div>, title: 'Step 0'
        },
        {
            component: <DeleteStep1 prevStep={handleGoBack} />, title: 'Step 1'
        }

    ];

    const containerStyle = {
        Width: '85%',
        margin: '10%',
        borderRadius: '8px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
    };

    return (
        <div style={containerStyle} >
            {steps[step - 0].component}
        </div>
    );
}

export default DeleteSimulation;

