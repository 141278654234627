import React, { useState, useEffect } from "react";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';


const RunChosenSimulation = ({ sendRoleMessage, handleSimValueChange, handleScenValueChange, buttonClicked, getSimName }) => {

    //#region Get and choose simulations
    const [shown, setShown] = useState(false);
    const [data, setData] = useState([]);
    const getInitialState = () => {
        const value = "Välj en sparad simulering";
        return value;
    };
    const [value, setValue] = useState(getInitialState);
    const [simName, setSimName] = useState("");

    useEffect(() => {
        fetch('/fetch-simulations')
            .then(response => response.json())
            .then(data => setData(data));

    }, []);

    const handleChangeOption = (e) => {
        const selectedValue = e.target.value;
        setValue(selectedValue);
        handleSimValueChange(selectedValue);
        setShown(true);
        const selectedOptionName = e.target.options[e.target.selectedIndex];
        const selectedSimName = selectedOptionName.textContent;
        setSimName(selectedSimName);
        getSimName(selectedSimName);
    };
    //#endregion

    //#region Get and select environments
    const [scenarioData, setScenarioData] = useState([]);
    const [selectedScenario, setSelectedScenario] = useState([]);
    const [roleMsg, setRoleMsg] = useState("");
    const [shownRole, setShownRole] = useState(false);

    useEffect(() => {
        fetch('/fetch-scenarios')
            .then(response => response.json())
            .then(scenarioData => setScenarioData(scenarioData));

    }, []);

    const filterScenarios = scenarioData.filter(scenario => scenario.idSimulation == value);
    
    const handleScenarioClick = (scenario) => {
        setSelectedScenario(scenario);
              handleScenValueChange(scenario.name);
        setValueRole('');
        setShownRole(true);
    };
    //#endregion

    //#region Get and send roles
    const [characterData, setCharacterData] = useState([]);
    const [hasData, setHasData] = useState([]);
    const [valueRole, setValueRole] = useState("");

    useEffect(() => {
        fetch('/fetch-characters')
            .then(response => response.json())
            .then(characterData => setCharacterData(characterData));

    }, []);

    useEffect(() => {
        fetch('/fetch-character-scenario-connection')
            .then(response => response.json())
            .then(hasData => setHasData(hasData));

    }, []);

    const filterScenario = hasData.filter(scenario => scenario.name == selectedScenario.name && scenario.idSimulation == value); 
    const filterCharacters = characterData.filter(obj1 => filterScenario.find(obj2 => obj2.idCharacter === obj1.idCharacter)); 

    const handleChangeOptionRole = (e) => {
        setValueRole(e.target.value);
        setRoleMsg(e.target.value);
    };

    useEffect(() => {
        if (buttonClicked) {
            setValueRole("");
            sendRoleMessage("")
        }
    }, [buttonClicked]);
    //#endregion
   
    return (
        <div className="run-simulation-container">

            <div className="choose-simulation-container">
                <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip id="container-tooltip">Search for simulations by entering the first letter</Tooltip>}
                >
                    <label id="choose-simulation-label">Choose a simulation to run:</label>
                </OverlayTrigger><br></br>
                <select id="choose-simulation" className="sim-dropdown" onChange={handleChangeOption} onClick={() => {
                    setRoleMsg("");
                    sendRoleMessage("");
                }}>
                    <option style={{ fontWeight: '600' }} >Choose a prepared simulation</option>
                    {data
                        .sort((a, b) => a.name.localeCompare(b.name))
                        .map((entity, index) => (
                            <option key={index} value={entity.idSimulation}>
                                {entity.name}
                            </option>
                        ))}
           
              </select>
            </div>

            {shown && (<div className="scenario-list" style={{ flex: 1, marginRight: "10px", marginTop: "20px" }}>
                <label id="choose-scenario-label">Choose an environment:</label>
                {filterScenarios.map((scenario, index) => (
                    <div 
                        key={index}
                        style={{
                            padding: "10px",
                            backgroundColor: scenario === selectedScenario ? "#8fbc8f" : "#eee",
                            cursor: "pointer",
                            margin: "10px",
                            color: "black",
                            borderRadius: '8px'
                        }}
                        onClick={() => {
                            handleScenarioClick(scenario);
                            setRoleMsg("");
                            sendRoleMessage("");
                        }}
                        


                    >
                        {scenario.name}
                    </div>
                ))}
            </div>)}    

            {shownRole && (<div className="roles-list" style={{ marginTop: "20px"}} onClick={() => sendRoleMessage(roleMsg)}>
                <label id="roles-list-label">Choose role to send to participant(s):</label><br></br>
                <select id="choose-role" style={{ width: '100%' }} value={valueRole} onChange={handleChangeOptionRole}  > 
                    <option style={{ fontWeight: '600' }} onClick={() => sendRoleMessage("")} value={""}>Choose a saved role</option>
                    {filterCharacters.map((entity, index) => (
                        <option style={{
                            overflow: "hidden",
                            overflowWrap: 'break-word', maxWidth: '100px', textOverflow: 'ellipsis'
                        }} key={index} value={entity.description}>{index+1}. {entity.description.length > 90 ? `${entity.description.slice(0, 90)}...` : entity.description} </option>

                    ))}
                </select>
            </div>)} 

        </div>

    );

}

export default RunChosenSimulation;
