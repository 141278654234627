import { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Container from "react-bootstrap/Container";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ListGroup from 'react-bootstrap/ListGroup';
import SaveSim from './SaveSim.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown, faArrowUp } from '@fortawesome/free-solid-svg-icons';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';



function EditConnections({ initialData}) {

    const [selectedScenarioIndex, setSelectedScenarioIndex] = useState(null);
    const [modalShow, setModalShow] = useState(false);
    const [selectedRoles, setSelectedRoles] = useState([]);
    const [data, setData] = useState(initialData);

    useEffect(() => {
        const updatedCombinedData = data.combinedData.map((scenario, index) => ({
            ...scenario,
            order: index + 1, // Add 1 to start the order from 1 instead of 0
        }));

        setData((prevData) => ({ ...prevData, combinedData: updatedCombinedData }));
    }, []);

    const moveScenario = (currentIndex, direction) => {
        const newIndex = currentIndex + direction;
        if (newIndex >= 0 && newIndex < data.combinedData.length) {
            const updatedCombinedData = [...data.combinedData];
            const [movedScenario] = updatedCombinedData.splice(currentIndex, 1);
            updatedCombinedData.splice(newIndex, 0, movedScenario);
            setData((prevData) => ({ ...prevData, combinedData: updatedCombinedData }));
        }
    };

    const handleArrowUpClick = (index) => {
        if (index > 0) {
            moveScenario(index, -1);
        }
    };

    const handleArrowDownClick = (index) => {
        if (index < data.combinedData.length - 1) {
            moveScenario(index, 1);
        }
    };

    useEffect(() => {
        if (selectedScenarioIndex !== null) {
            const scenario = data.combinedData[selectedScenarioIndex];
            const correlatedRoleIndices = scenario.roles.map((role) =>
                data.roleData.findIndex((dataRole) => dataRole.description === role.description)
            );
            setSelectedRoles(correlatedRoleIndices);
        }
    }, [selectedScenarioIndex, data]);

    const handleScenarioSelection = (index) => {
        setSelectedScenarioIndex(index);
    };

    const handleRoleSelection = (index) => {
        const isSelected = selectedRoles.includes(index);
        let newSelectedRoles = [];

        if (isSelected) {
            newSelectedRoles = selectedRoles.filter((roleIndex) => roleIndex !== index);
        } else {
            newSelectedRoles = [...selectedRoles, index];
        }

        setSelectedRoles(newSelectedRoles);

        if (selectedScenarioIndex !== null) {
            const updatedCombinedData = [...data.combinedData];
            const scenario = updatedCombinedData[selectedScenarioIndex];
            scenario.roles = newSelectedRoles.map((roleIndex) => data.roleData[roleIndex]);
            setData({ ...data, combinedData: updatedCombinedData });
        }
    };

    //#region Handle the "Submit" button click
    const handleSave = () => {
        setModalShow(true);
    };

    const handleSubmission = () => {
        const updatedData = { ...data };
        updatedData.combinedData.forEach((scenario, index) => {
            scenario.order = index;
        });
        handleSubmit(updatedData);
    };

    const handleCloseSubmission = () => {
        setModalShow(false);
    };

    const [response, setResponse] = useState('');
    function handleSubmit(data) {
        setData(data);
        replaceRolesWithDescriptions(data);

        handleSubmitPost(data)
            .then(response => {
                setResponse(response);
            });
    }

    function replaceRolesWithDescriptions(data) {
        if (data && data.combinedData && Array.isArray(data.combinedData)) {
            data.combinedData.forEach((scenario) => {
                if (scenario.roles && Array.isArray(scenario.roles)) {
                    scenario.roles = scenario.roles.map((role) => role.description);
                } else {
                    scenario.roles = []; // If roles is not defined or not an array, replace it with an empty array
                }
                scenario.name = data.simulation.simName;
            });
        }
    }

    function handleSubmitPost(data) {
        const url = '/edit-simulation';

        const postData = {
            sAndCConnection: data.combinedData,
            simulationId: data.simulation.simId
        };
        return fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(postData)
        })
            .then(response => {
                if (response.ok) {
                    return { success: true, message: 'Data was successfully posted!' };
                } else {
                    return { success: false, message: 'Something went wrong!' };
                }
            })
            .catch(error => {
                console.error('Network error:', error);
                return { success: false, message: 'Something went wrong!' };
            });
    }
    //#endregion

    return (
        <div>
            <Row>
                <h1 style={{ textAlign: 'center', marginBottom: '40px' }}>You are editing the simulation: {data.simulation.simName}</h1>
            </Row>
            <div style={{ border: "1.5px solid #fff", borderRadius: "6px", padding: "15px 25px 20px 20px " }}>
                <Row>
                    <ul className="steps">
                        <li>1: Edit name, environments & roles</li>
                        <li className="is-active">2: Edit environment-roles connections</li>
                    </ul>
                </Row>
                <Row>
                    <Col>
                        <Row style={{ marginTop: '20px' }}>
                            <label style={{ fontWeight: "bold" }}>Environments</label>
                        </Row>
                        <Container style={{ maxHeight: "200px", minHeight: "200px", overflowY: "auto" }}>
                            <ListGroup>
                                {data.combinedData.map((scenario, index) => (
                                    <ListGroup.Item
                                        key={index}
                                        onClick={() => handleScenarioSelection(index)}
                                        style={{
                                            backgroundColor: selectedScenarioIndex === index ? '#C1E1C1' : 'white',
                                            cursor: 'pointer'
                                        }}
                                    >
                                        <Container>
                                            <Row>
                                                <div className="col-1" onClick={() => handleArrowUpClick(index)}>
                                                    <FontAwesomeIcon icon={faArrowUp} style={{ color: 'black', cursor: 'pointer' }} />
                                                </div>
                                                <div className="col-1" onClick={() => handleArrowDownClick(index)}>
                                                    <FontAwesomeIcon icon={faArrowDown} style={{ color: 'black', cursor: 'pointer' }} />
                                                </div>
                                                <OverlayTrigger
                                                    placement="top"
                                                    overlay={<Tooltip id={`tooltip-${index}`}>{scenario.scenarioName}</Tooltip>}
                                                >
                                                    <div style={{ maxWidth: "150px", overflow: "hidden", textOverflow: "ellipsis" }}>
                                                        {scenario.scenarioName}
                                                    </div>
                                                </OverlayTrigger>
                                            </Row>
                                        </Container>
                                    </ListGroup.Item>
                                ))}
                            </ListGroup>
                        </Container>
                    </Col>
                    <Col>
                        <Row style={{ marginTop: '20px' }}>
                            <label style={{ fontWeight: "bold" }}>Roles</label>
                        </Row>
                        <Container style={{ maxHeight: "200px", minHeight: "200px", overflowY: "auto" }}>
                            <ListGroup>
                                {data.roleData.map((role, index) => (
                                    <ListGroup.Item
                                        key={index}
                                        onClick={() => handleRoleSelection(index)}
                                        style={{
                                            backgroundColor: selectedRoles.includes(index) ? '#C1E1C1' : 'white',
                                            cursor: 'pointer'
                                        }}
                                    >
                                        <Container>
                                            <Row>

                                                <OverlayTrigger
                                                    placement="top"
                                                    overlay={<Tooltip id={`tooltip-${index}`}>{role.description}</Tooltip>}
                                                >
                                                    <div style={{ maxWidth: "150px", overflow: "hidden", textOverflow: "ellipsis" }}>
                                                        {role.description}
                                                    </div>
                                                </OverlayTrigger>
                                            </Row>
                                        </Container>
                                    </ListGroup.Item>
                                ))}
                            </ListGroup>
                        </Container>
                    </Col>
                </Row>
                <div>
                <Row>
                <OverlayTrigger
                    placement="top"
                            overlay={<Tooltip id="container-tooltip">Select the environment you want to edit and then select/deselect the roles you want to connect to it. If you added a new environment you will have to add new roles to it, but old environments will be saved as before unless you edit it. <br></br><br></br>You can also edit the order of the environments by clicking the up/down buttons. <br></br><br></br> Once satisfied with all connections, click the save button below.</Tooltip>}
                >
                            <p style={{ cursor: 'pointer', marginTop: "20px", fontSize: '13px' }}><span className="question-mark" style={{ fontSize: '18px', fontFamily: 'Segoe UI Emoji' }} > &#8505; </span > How do I edit the connections?</p>
                    </OverlayTrigger>
                    </Row>
                </div>
                
                <Row>
                    <Col style={{ marginTop: '30px' }}>   <Button onClick={handleSave}>Save edited simulation</Button></Col>
                </Row>

            </div>

            <SaveSim
                modalShow={modalShow}
                handleCloseSubmission={handleCloseSubmission}
                submit={handleSubmission}
                response={response}
            />
        </div>
    );
}

export default EditConnections;