import React, { useState, useEffect } from "react";
import * as signalR from "@microsoft/signalr";
import sound from '../../../src/Assets/notification.wav';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Alert from 'react-bootstrap/Alert';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';


const MessageToUsers = () => {
    const [connection, setConnection] = useState(null);
    const [message, setMessage] = useState(null);
    const [isMessageRead, setIsMessageRead] = useState(false);
    const [showAlert, setShowAlert] = useState(true);
    const [recentMessage, setRecentMessage] = useState(null);
   
    const [messageRead, setMessageRead] = useState(false,recentMessage );
   

    const fetchRecentMessage = (userId) => {
        const queryParams = `?userId=${encodeURIComponent(userId)}`;

        fetch(`/fetch-recentMessage${queryParams}`)
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else if (response.status === 404) {
                    throw new Error('Participant role not found');
                } else {
                    throw new Error('An error occurred');
                }
            })
            .then(data => setRecentMessage(data))
            .catch(error => {
                console.error('An error occurred:', error);
            });
    };

    useEffect(() => {
        if (recentMessage !== null) {
            setMessage(recentMessage);
        }
    }, [recentMessage]);

    useEffect(() => {

        const connection = new signalR.HubConnectionBuilder()
            .withUrl('/chatHub', {
                //skipNegotiation: true,
                //transport: signalR.HttpTransportType.WebSockets
                transport: signalR.HttpTransportType.LongPolling,

                   accessTokenFactory: () => {
                       // Determine if the client is reconnecting
                       const isReconnecting = connection.state === signalR.HubConnectionState.Reconnecting;
                       const data = {
                           isReconnecting: isReconnecting ? 'true' : 'false',
                           userNameRCR: localStorage.getItem("UserNameRCR")
                       };

                       const accessTokenString = JSON.stringify(data);

                       // Return the boolean value as the access token
                       return accessTokenString;
                },
               
            })
            .withAutomaticReconnect()
            .configureLogging(signalR.LogLevel.None)
            .build();

        connection.start().then(() => {
            console.log("SignalR connected");
        });


        connection.on("ReceiveMessage", (message) => {
            setMessage(message);
            setShowAlert(true);
        //    play();
        });

        connection.on("UserConnected", (connectionId) => {

            //Set initial connectionId to "userNameRCR" in localstorage
            if (localStorage.getItem("UserNameRCR") === null) {

                localStorage.setItem("UserNameRCR", connectionId);
            }

            connection.invoke("AddUser", localStorage.getItem("UserNameRCR"))
                .then(() => {
                    fetchRecentMessage(localStorage.getItem("UserNameRCR"));

                })
                .catch((error) => {
                    console.error(error);
                });
        });

        connection.on("UserDisconnected", (connectionId) => {
            connection.invoke("RemoveConnectionFromUser", localStorage.getItem("UserNameRCR"), connectionId)
                .then(() => {
                })
                .catch((error) => {
                    console.error(error);
                });
        });

        connection.on('MessageRead', (message) => {
            setIsMessageRead(true);
        });


        connection.onreconnecting((error) => {
            
            console.log("SignalR Reconnecting");
             
        });


        connection.onreconnected(() => {
            console.log("SignalR reconnected");
              
                setShowAlert(true);
             //     play();
        });

        setConnection(connection);

        return () => {
            connection.stop();
        };
    }, []);



    function handleMarkMessageRead() {
        connection.invoke('MarkMessageRead', message, localStorage.getItem("UserNameRCR"));
    }

    function newMessage() {
        setShowAlert(false);
        handleMarkMessageRead();
    }

    //function play() {
    //    new Audio(sound).play()
    //}

    const cardStyle = {
        fontWeight: "500",
        fontSize: "25px",
        background: "#414148",
        border: "1px solid #fff",
       overflow: 'auto',
    };

    const buttonStyle = {
        padding: '10px',
        fontSize: '15px', 
    };



    return (
        <Container style={{ display: 'grid', gridTemplateRows: 'auto 1fr auto', height: '80vh' }}>
            <Row>
                <Col style={{
                    height: '10vh'}}>
                    {showAlert && (
                        <Alert variant="success">
                            You have a new message!
                        </Alert>
                    )}
                </Col>
            </Row>
            <Row>
                <Col>
                    <Card body style={{ ...cardStyle, height: '60vh' }} className="text-center">
                        {message ? (
                            <p>{message}</p>
                        ) : (
                            <p>Loading...</p>
                        )}
                     
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col className="d-flex justify-content-center align-items-end">
                   
                    { showAlert && (
                        <Button style={buttonStyle} variant="info" onClick={newMessage} >
                                I have read this message
                            </Button>
                    )}
                </Col>
            </Row>
        </Container>
    );
}

export default MessageToUsers;