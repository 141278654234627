import { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Container from "react-bootstrap/Container";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import InputGroup from 'react-bootstrap/InputGroup';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

function EditStep2({ onSubmit, simulation, scenarios, prevStep }) {

    const [scenarioData, setScenarioData] = useState([]);
    const [formData, setFormData] = useState([{ name: '' }]);

    useEffect(() => {
        fetch('/fetch-scenarios')
            .then(response => response.json())
            .then(scenarioData => {
                setScenarioData(scenarioData);

                if (Array.isArray(scenarios)) {
                    setFormData(scenarios);
                }
                else {
                    const filterScenarios = scenarioData.filter(scenario => scenario.idSimulation === parseInt(simulation.simid)); //change to value of dimulation Id
                    const scenarioList = filterScenarios.push('');
                    setFormData(filterScenarios);
                }

            });
    }, []);

    function handleSubmit(event) {
        event.preventDefault();
        onSubmit(formData);
    }

    function handleInputChange(event, index) {
        const { name, value } = event.target;
        const updatedFormData = [...formData];
        updatedFormData[index] = {
            ...updatedFormData[index],
            [name]: value
        };
        setFormData(updatedFormData);
    }

    function handleAdd() {
        setFormData([...formData, { name: '' }]);
    }

    const removeItem = (index) => {
        if (formData.length > 1){
            setFormData(formData.filter((o, i) => index !== i));
        }
    };

    return (

        <div >
            <Row>
                <h1 style={{ textAlign: 'center', marginBottom: '40px' }}>You are creating the simulation: {simulation.name} <span><h4>using the existing simulation: {simulation.simname} as template</h4></span></h1>
            </Row>
            
            <div style={{ border: "1.5px solid #fff", borderRadius: "6px", paddingLeft: '10px' }}>
                <Row>
                    <ul className="steps">
                        <li>1: Template & name</li>
                        <li className="is-active">2: Environments</li>
                        <li>3: Roles</li>
                        <li>4: Connect roles to environments</li>
                        <li>5: Save simulation</li>
                    </ul>
                </Row>

                <Row style={{ margin: "5% 0" }}>

                    <Col>
                        <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip id="container-tooltip">if you want to prepare messages not connected to an environment, you could name an environment 'other messages, start, end' etc.</Tooltip>}
                        >
                            <p><span className="question-mark" style={{ fontSize: '18px', fontFamily: 'Segoe UI Emoji' }} > &#8505; </span>Add environments to the simulation:</p>
                        </OverlayTrigger>

                        <Container>
                            <form onSubmit={handleSubmit}  >

                                <Row>
                                    <InputGroup>
                                        <Col xs={9}>
                                            {formData.length > 0 && (
                                                <div>
                                                    <label>

                                                        <input type="text" name="name" value={formData[formData.length - 1].name} onChange={(event) => handleInputChange(event, formData.length - 1)} />

                                                    </label>
                                                </div>
                                            )}
                                        </Col>
                                        <Col>
                                            <InputGroup.Text style={{ backgroundColor: "transparent", border: "none" }}>
                                                <Button style={{ paddingBottom: '8px', marginLeft: '-8px' }} type="button" onClick={handleAdd}>+</Button>

                                            </InputGroup.Text>
                                        </Col>
                                    </InputGroup>

                                </Row>

                            </form>
                        </Container>
                    </Col>

                    <Col>
                        <div style={{ maxHeight: "340px", overflowY: "auto", width: "100% " }}>
                            <p style={{ fontWeight: 'bold' }}>Added environments ({formData.length - 1}):</p>
                            <ul style={{ wordWrap: "break-word", listStyle: 'none' }}>
                                {formData.map((data, index) => (
                                    <li key={index}>{data.name}  {index !== formData.length - 1 && (<span className="scenario-delete" onClick={() => removeItem(index)}> X</span>)}
                                    </li>

                                ))}
                            </ul>
                        </div>
                    </Col>

                </Row>
                <Row style={{ marginTop: '30px', padding: "20px" }}>
                    <Col className="text-start">
                        <Button onClick={prevStep} >{' <'} Previous step</Button>
                    </Col>
                    <Col className="text-end">
                        <form onSubmit={handleSubmit} >
                            <Button type="submit">Next step{' >'}</Button>
                        </form>
                    </Col>

                </Row>
            </div>
        </div>
       

    );
}

export default EditStep2;









