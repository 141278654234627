import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import React, { useState } from "react";
import { NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import Alert from 'react-bootstrap/Alert';


const SaveSimModal = ({ modalShow, handleCloseSubmission, submit, response }) => {

    const [isSaved, setIsSaved] = useState(false);



    const handleSave = () => {
        setIsSaved(true);
        submit();
    };

    return (
        <Modal show={modalShow} onHide={handleCloseSubmission}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            style={{color:"black"} }
        >
            <Modal.Header closeButton>
                <Modal.Title>{isSaved ? "Status" : "Save edited simulation"}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {isSaved ? (
                    <Alert variant={response.success ? "success" : "danger"}>
                        The simulation has been successfully edited!
                    </Alert>
                ) : (
                    "Are you sure you want to save the edited simulation?"
                )}
            </Modal.Body>
            <Modal.Footer>
                {isSaved ? (
          

                    <Button style={{ marginTop: '-10px', marginBottom: '20px' }}>
                        <NavLink tag={Link} className="text-light" to="/">Ok</NavLink>
                    </Button>
                ) : (
                    <>
                        <Button variant="danger" onClick={handleCloseSubmission}>
                            Close
                        </Button>
                        <Button variant="primary" onClick={handleSave}>
                            Save
                        </Button>
                    </>
                )}
            </Modal.Footer>
        </Modal>
    );
};

export default SaveSimModal;


