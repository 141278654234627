import { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Container from "react-bootstrap/Container";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ListGroup from 'react-bootstrap/ListGroup';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes, faEdit, faPlus } from '@fortawesome/free-solid-svg-icons';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

function EditScenarioAndRoles({ onSubmit }) {

    const getInitialState = () => {
        const value = "Choose a saved simulation to use as template";
        return value;
    };
    const [data, setData] = useState([]);
    const [value, setValue] = useState(getInitialState);
    const [formData, setFormData] = useState({});
    const [simName, setSimName] = useState("");
    const [scenarioData, setScenarioData] = useState([]);
    const [connectionData, setConnectionData] = useState([]);
    const [roleData, setRoleData] = useState([]);
    const [dataToSubmit, setDataToSubmit] = useState(null);
    const [combinedData, setCombinedData] = useState([]);
    const [editingSimName, setEditingSimName] = useState(false);
    const [editedSimName, setEditedSimName] = useState('');
    const [editingScenarioIndex, setEditingScenarioIndex] = useState(null);
    const [editedScenarioName, setEditedScenarioName] = useState('');
    const [editingRoleIndex, setEditingRoleIndex] = useState(null);
    const [editedRoleDescription, setEditedRoleDescription] = useState('');

    useEffect(() => {
        fetch('/fetch-simulations')
            .then(response => response.json())
            .then(data => setData(data));
    }, []);

    useEffect(() => {
        fetch('/fetch-scenarios')
            .then(response => response.json())
            .then(scenarioData => {
                setScenarioData(scenarioData);
                const intValue = parseInt(value); 
                const filterScenarios = scenarioData.filter(scenario => scenario.idSimulation === intValue);
                setScenarioData(filterScenarios);

                // Fetch scenarios when value changes
                fetch(`/fetch-character-scenario-connection-simId?simId=${value}`)
                    .then(response => response.json())
                    .then(connectionData => {
                        setConnectionData(connectionData);

                        const idCharacterValues = connectionData.map(item => item.idCharacter);

                        fetch(`/fetch-characters-id?ids=${JSON.stringify(idCharacterValues)}`)
                            .then(response => response.json())
                            .then(roleData => {
                                setRoleData(roleData);

                                const newCombinedData = [];

                                connectionData.forEach((connection) => {
                                    const { idCharacter, name } = connection;

                                    const matchingRole = roleData.find((role) => role.idCharacter === idCharacter);

                                    const matchingScenario = scenarioData.find((scenario) => scenario.name === name);

                                    if (matchingRole && matchingScenario) {
                                        const scenarioName = matchingScenario.name;

                                        const existingCombinedData = newCombinedData.find((data) => data.scenarioName === scenarioName);

                                        if (existingCombinedData) {
                                            existingCombinedData.roles.push({ description: matchingRole.description });
                                        } else {
                                            newCombinedData.push({
                                                scenarioName,
                                                roles: [{ description: matchingRole.description }],
                                            });
                                        }
                                    }
                                });
                                setCombinedData(newCombinedData);  
                            });
                    });
            });
    }, [value]);


    useEffect(() => {
        const uniqueRoleData = roleData
            .reduce((accumulator, currentRole) => {
                const existingRole = accumulator.find(role => role.description === currentRole.description);
                if (!existingRole) {
                    accumulator.push(currentRole);
                }
                return accumulator;
            }, []);

        setRoleData(uniqueRoleData);
    }, [combinedData]);

    const handleChangeOption = (e) => {
        const selectedValue = e.target;
        const simid = selectedValue.options[selectedValue.selectedIndex].value;
        const simname = selectedValue.options[selectedValue.selectedIndex].text;
        setSimName(simname);
        setValue(simid);

        setFormData({
            ...formData,
            simid: simid,
            simname: simname
        });
    };

    useEffect(() => {
        if (dataToSubmit !== null) {
            onSubmit(dataToSubmit);
        }
    }, [dataToSubmit, onSubmit]);

    function handleSubmit(event) {
        event.preventDefault();

        // Create the dataToSubmit object
        const sim = {
            simId: parseInt(value),
            simName: simName
        }
        const dataToSubmit = {
            simulation: sim,
            roleData: roleData,
            combinedData: combinedData,
            connectionData: connectionData,
            scenarioData: scenarioData
        };
        setDataToSubmit(dataToSubmit);
    }


    const contentStyle = {
        borderColor: '#4C6C84',
        color: '#fff',
        padding: '12px 24px',
        borderRadius: '0px 0px  8px  8px',
        paddingTop: '20px',
        fontSize: '16px',
        overflow: 'auto',
        width: '740px'
    }


    //#region Handle delete role

    // Function to remove a role
    function handleRemoveRole(index) {
        const roleToRemove = roleData[index].description;
        const updatedRoles = [...roleData];
        updatedRoles.splice(index, 1);
        setRoleData(updatedRoles);

        // Update combinedData to remove the role
        const updatedCombinedData = combinedData.map((item) => {
            const roles = item.roles.filter((role) => role.description !== roleToRemove);
            return { ...item, roles };
        });

        setCombinedData(updatedCombinedData);
    }
    //#endregion

    //#region Handle edit environment description

    // Function to enter edit mode
    const handleEditScenario = (index) => {
        setEditingScenarioIndex(index);
        setEditedScenarioName(combinedData[index].scenarioName); // Use scenarioName from combinedData
    };

    // Function to save edited scenario name
    const handleSaveScenario = (index) => {
        const updatedCombinedData = [...combinedData];
        updatedCombinedData[index].scenarioName = editedScenarioName; // Update scenarioName in combinedData

        // If you need to update scenarioData as well, you can create a new array and set it.
        const updatedScenarioData = [...scenarioData];
        updatedScenarioData[index].name = editedScenarioName;

        setScenarioData(updatedScenarioData);
        setCombinedData(updatedCombinedData); // Update combinedData

        setEditingScenarioIndex(null); // Exit edit mode
    };

    // Function to cancel editing
    const handleCancelEdit = () => {
        setEditingScenarioIndex(null);
        setEditedScenarioName('');
    };

    // Handle input changes
    const handleScenarioNameChange = (event) => {
        setEditedScenarioName(event.target.value);
    };
    //#endregion

    //#region Handle delete environment
    function handleRemoveScenario(index) {
        const updatedCombinedData = [...combinedData];
        updatedCombinedData.splice(index, 1);

        // If you need to update scenarioData as well, you can create a new array and set it.
        const updatedScenarioData = [...scenarioData];
        updatedScenarioData.splice(index, 1);

        setScenarioData(updatedScenarioData);
        setCombinedData(updatedCombinedData); // Update combinedData
    }
    //#endregion

    //#region Handle edit role description

    // Function to enter edit mode for role description
    const handleEditRole = (index) => {
        setEditingRoleIndex(index);
        setEditedRoleDescription(roleData[index].description);
    };

    // Function to save edited role description
    const handleSaveRole = (index) => {
        const updatedRoleData = [...roleData];
        const oldDescription = roleData[index].description;
        const newDescription = editedRoleDescription;

        // Update roleData with the new description
        updatedRoleData[index].description = newDescription;
        setRoleData(updatedRoleData);
        setEditingRoleIndex(null); // Exit edit mode

        // Update combinedData with the new role description
        const updatedCombinedData = combinedData.map((item) => {
            const roles = item.roles.map((role) => {
                if (role.description === oldDescription) {
                    return { description: newDescription };
                }
                return role;
            });
            return { ...item, roles };
        });

        setCombinedData(updatedCombinedData);
        
    };

    // Function to cancel editing for role description
    const handleCancelRoleEdit = () => {
        setEditingRoleIndex(null);
        setEditedRoleDescription('');
    };

    // Handle input changes for role description
    const handleRoleDescriptionChange = (event) => {
        setEditedRoleDescription(event.target.value);
    };
    //#endregion

    //#region Handle add new role/environment
    const [showAddScenarioModal, setShowAddScenarioModal] = useState(false);
    const [newScenarioName, setNewScenarioName] = useState('');

    const handleOpenAddScenarioModal = () => {
        setShowAddScenarioModal(true);
    };

    const handleCloseAddScenarioModal = () => {
        setShowAddScenarioModal(false);
    };

    const handleNewScenarioNameChange = (event) => {
        setNewScenarioName(event.target.value);
    };

    const handleAddScenario = () => {
        const newScenario = {
            scenarioName: newScenarioName,
            roles: []
        };
        setCombinedData([...combinedData, newScenario]);
        // setScenarioData([...scenarioData, newScenario]);
        setNewScenarioName('');
        handleCloseAddScenarioModal();
    };

    const [showAddRoleModal, setShowAddRoleModal] = useState(false);
    const [newRoleDescription, setNewRoleDescription] = useState('');

    const handleOpenAddRoleModal = () => {
        setShowAddRoleModal(true);
    };

    const handleCloseAddRoleModal = () => {
        setShowAddRoleModal(false);
    };

    const handleNewRoleDescriptionChange = (event) => {
        setNewRoleDescription(event.target.value);
    };

    const handleAddRole = () => {
        const newRole = {
            idCharacter: null,
            name: null,
            idParticipants: [],
            description: newRoleDescription,
        };
        setRoleData([...roleData, newRole]);
        setNewRoleDescription('');
        handleCloseAddRoleModal();
    };
    //#endregion

    return (
        <div>
            <Row>
                <h1 style={{ textAlign: 'center', marginBottom: '40px' }}>Edit a simulation</h1>
            </Row>
            <div style={{ border: "1.5px solid #fff", borderRadius: "6px", padding: "10px" }}>
                <Row>
                    <ul className="steps">
                        <li className="is-active">1: Edit name, environments & roles</li>
                        <li>2: Edit environment-roles connections</li>
                    </ul>
                </Row>
                <Row>
                    <div className="choose-simulation-container" style={{ padding: " 0 10px 10px  10px" }}>
                        <label id="choose-simulation-label">Choose a simulation to edit:</label>
                        <select style={{ width: "95%" }} id="choose-simulation" onChange={handleChangeOption}>
                            <option >Choose a simulation to edit</option>
                            {data.map((entity, index) => (
                                <option key={index} value={entity.idSimulation}>{entity.name}</option>
                            ))}
                        </select>
                    </div>
                </Row>
                {simName !== "" && (
                    <Row>
                        <form onSubmit={handleSubmit} style={contentStyle} >
                            <Container>
                                <Row>
                                    <Row>
                                        <Col className="d-flex align-items-center justify-content-center">
                                            <div>
                                                <FontAwesomeIcon
                                                    icon={faEdit}
                                                    style={{ color: 'white', cursor: 'pointer', fontSize: '30px' }}
                                                    onClick={() => setEditingSimName(true)} // Enable editing
                                                />
                                            </div>
                                            {editingSimName ? (
                                                <div>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        value={editedSimName}
                                                        onChange={(e) => setEditedSimName(e.target.value)}
                                                        autoFocus
                                                    />
                                                </div>
                                            ) : (
                                                <div style={{ overflow: 'hidden', textOverflow: 'ellipsis', fontSize: '30px', marginLeft: '10px' }}>
                                                    {simName}
                                                </div>
                                            )}
                                        </Col>
                                    </Row>
                                    {editingSimName && (
                                        <Row>
                                            <Col className="text-center mt-3">
                                                <FontAwesomeIcon
                                                    icon={faCheck}
                                                    style={{ color: 'green', cursor: 'pointer', fontSize: '20px' }}
                                                    onClick={() => {
                                                        setSimName(editedSimName);
                                                        setEditingSimName(false); // Exit edit mode
                                                        // You can save the editedSimName to the state or perform any other action here.
                                                    }}
                                                />
                                                <FontAwesomeIcon
                                                    icon={faTimes}
                                                    style={{ color: 'red', cursor: 'pointer', fontSize: '20px', marginLeft: '10px' }}
                                                    onClick={() => {
                                                        // Handle the cancel action here
                                                        setEditingSimName(false); // Exit edit mode without saving
                                                    }}
                                                />
                                            </Col>
                                        </Row>
                                    )}
                                </Row>
                                <Row>
                                    <Col>
                                        <Row style={{ marginTop: '20px' }}>
                                            <label style={{ fontWeight: "bold" }}>Overview environments</label>
                                        </Row>
                                        <Row>
                                            <Container style={{ maxHeight: "200px", minHeight: "200px", overflowY: "auto" }}>
                                                <ListGroup>
                                                    {combinedData.map((scenario, index) => (
                                                        <ListGroup.Item key={index}>
                                                            <Container>
                                                                <Row>
                                                                    <div className="col-1">
                                                                        {editingScenarioIndex === index ? <div></div> : (
                                                                            <FontAwesomeIcon
                                                                                icon={faEdit}
                                                                                style={{ color: 'black', cursor: 'pointer' }}
                                                                                onClick={() => handleEditScenario(index)}
                                                                            />
                                                                        )}
                                                                    </div>
                                                                    <div className="col-9">
                                                                        {editingScenarioIndex === index ? (
                                                                            <Row className="align-items-center">
                                                                                <Col xs={9}> {/* Adjust the column size as needed */}
                                                                                    <input
                                                                                        type="text"
                                                                                        className="form-control"
                                                                                        value={editedScenarioName}
                                                                                        onChange={handleScenarioNameChange}
                                                                                        autoFocus
                                                                                    />
                                                                                </Col>
                                                                                <Col xs={1}> {/* Adjust the column size as needed */}
                                                                                    <FontAwesomeIcon
                                                                                        icon={faCheck}
                                                                                        style={{
                                                                                            color: 'green',
                                                                                            cursor: 'pointer',
                                                                                            fontSize: '20px',
                                                                                        }}
                                                                                        onClick={() => handleSaveScenario(index)}
                                                                                    />
                                                                                </Col>
                                                                                <Col xs={1}> {/* Adjust the column size as needed */}
                                                                                    <FontAwesomeIcon
                                                                                        icon={faTimes}
                                                                                        style={{
                                                                                            color: 'red',
                                                                                            cursor: 'pointer',
                                                                                            fontSize: '20px',
                                                                                        }}
                                                                                        onClick={handleCancelEdit}
                                                                                    />
                                                                                </Col>
                                                                            </Row>
                                                                        ) : (
                                                                            <OverlayTrigger
                                                                                placement="top" // Adjust the placement as needed (e.g., top, bottom, left, right)
                                                                                overlay={<Tooltip id={`tooltip-${index}`}>{scenario.scenarioName}</Tooltip>}
                                                                            >
                                                                                <div style={{ maxWidth: "150px", overflow: "hidden", textOverflow: "ellipsis" }}>
                                                                                    {scenario.scenarioName}
                                                                                </div>
                                                                            </OverlayTrigger>
                                                                        )}
                                                                    </div>
                                                                    <div className="col-1">
                                                                        {editingScenarioIndex !== index && (
                                                                            <FontAwesomeIcon
                                                                                icon={faTimes}
                                                                                style={{ color: 'red', cursor: 'pointer' }}
                                                                                onClick={() => handleRemoveScenario(index)}
                                                                            />
                                                                        )}
                                                                    </div>
                                                                </Row>
                                                            </Container>
                                                        </ListGroup.Item>
                                                    ))}
                                                </ListGroup>

                                            </Container>
                                            <div className="text-center mt-3">
                                                <div className="d-flex flex-column align-items-center">
                                                    <FontAwesomeIcon icon={faPlus} onClick={handleOpenAddScenarioModal} style={{ fontSize: '24px', cursor: 'pointer' }} />
                                                    <p className="text-center">Add environment</p>
                                                </div>

                                            </div>
                                        </Row>
                                    </Col>

                                    <Col>
                                        <Row style={{ marginTop: '20px' }}>
                                            <label style={{ fontWeight: "bold" }}>Overview roles</label>
                                        </Row>
                                        <Row>
                                            <Container style={{ maxHeight: "200px", minHeight: "200px", overflowY: "auto" }}>
                                                <ListGroup>
                                                    {roleData.map((role, index) => (
                                                        <ListGroup.Item key={index}>
                                                            <Container>
                                                                <Row>
                                                                    <div className="col-1">
                                                                        {editingRoleIndex === index ? <div></div> : (
                                                                            <FontAwesomeIcon
                                                                                icon={faEdit}
                                                                                style={{ color: 'black', cursor: 'pointer' }}
                                                                                onClick={() => handleEditRole(index)}
                                                                            />
                                                                        )}
                                                                    </div>
                                                                    <div className="col-9">
                                                                        {editingRoleIndex === index ? (
                                                                            <Row className="align-items-center">
                                                                                <Col xs={9}>
                                                                                    <input
                                                                                        type="text"
                                                                                        className="form-control"
                                                                                        value={editedRoleDescription}
                                                                                        onChange={handleRoleDescriptionChange}
                                                                                        autoFocus
                                                                                    />
                                                                                </Col>
                                                                                <Col xs={1}>
                                                                                    <FontAwesomeIcon
                                                                                        icon={faCheck}
                                                                                        style={{
                                                                                            color: 'green',
                                                                                            cursor: 'pointer',
                                                                                            fontSize: '20px',
                                                                                        }}
                                                                                        onClick={() => handleSaveRole(index)}
                                                                                    />
                                                                                </Col>
                                                                                <Col xs={1}>
                                                                                    <FontAwesomeIcon
                                                                                        icon={faTimes}
                                                                                        style={{
                                                                                            color: 'red',
                                                                                            cursor: 'pointer',
                                                                                            fontSize: '20px',
                                                                                        }}
                                                                                        onClick={handleCancelRoleEdit}
                                                                                    />
                                                                                </Col>
                                                                            </Row>
                                                                        ) : (
                                                                            < OverlayTrigger
                                                                                placement="top" // Adjust the placement as needed (e.g., top, bottom, left, right)
                                                                                overlay={<Tooltip id={`tooltip-${index}`}>{role.description}</Tooltip>}
                                                                            >
                                                                                <div style={{ maxWidth: "150px", overflow: "hidden", textOverflow: "ellipsis" }}>
                                                                                    {role.description}
                                                                                </div>
                                                                            </OverlayTrigger>
                                                                        )}
                                                                    </div>
                                                                    <div className="col-1">
                                                                        {editingRoleIndex !== index && (
                                                                            <FontAwesomeIcon
                                                                                icon={faTimes}
                                                                                style={{ color: 'red', cursor: 'pointer' }}
                                                                                onClick={() => handleRemoveRole(index)}
                                                                            />
                                                                        )}
                                                                    </div>
                                                                </Row>
                                                            </Container>
                                                        </ListGroup.Item>
                                                    ))}

                                                </ListGroup>


                                            </Container>
                                            <Row className="text-center mt-3">
                                                <div className="d-flex flex-column align-items-center">
                                                    <FontAwesomeIcon icon={faPlus} onClick={handleOpenAddRoleModal} style={{ fontSize: '24px', cursor: 'pointer' }} />
                                                    <p className="text-center">Add Role</p>
                                                </div>
                                            </Row>
                                        </Row>
                                    </Col>

                                </Row>

                                <Row>
                                    <Col></Col>
                                    <Col className="text-end" style={{ marginTop: '30px' }}> <Button type="submit">Edit connections{' >'} </Button></Col>
                                </Row>
                            </Container>
                        </form>
                    </Row>
                )}
            </div>

            {/* "Add Scenario" Modal */}
            <Modal show={showAddScenarioModal} onHide={handleCloseAddScenarioModal}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title style={{ color: "black" }}>Add environment</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group>
                            <Form.Label>Environment Name</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter environment name"
                                value={newScenarioName}
                                onChange={handleNewScenarioNameChange}
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseAddScenarioModal}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleAddScenario}>
                        Add environment
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* "Add Role" Modal */}
            <Modal show={showAddRoleModal} onHide={handleCloseAddRoleModal} aria-labelledby="contained-modal-title-vcenter"
                centered>
                <Modal.Header closeButton>
                    <Modal.Title style={{color:"black"} }>Add Role</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group>
                            <Form.Label>Role Description</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter role description"
                                value={newRoleDescription}
                                onChange={handleNewRoleDescriptionChange}
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseAddRoleModal}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleAddRole}>
                        Add Role
                    </Button>
                </Modal.Footer>
            </Modal>

        </div>

    );
}

export default EditScenarioAndRoles;
