import { useState, useEffect } from 'react';
import DeleteSimMessage from "./DeleteSimModal";
import ListGroup from 'react-bootstrap/ListGroup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import Row from 'react-bootstrap/Row';

function DeleteStep1() {

    const [simulations, setSimualtions] = useState([]);
    const [modalShow, setModalShow] = useState(false);
    const [selectedSimulation, setSelectedSimulation] = useState([]);
    
    useEffect(() => {
        fetch('/fetch-simulations')
            .then(response => response.json())
            .then(simulations => setSimualtions(simulations));
    }, [handleDelete]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    function handleDelete(simulation) {

        setModalShow(true);
        const url = '/delete-simulation';

        const selectedSimIndex = {
            simulationId: simulation.idSimulation
        };

        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(selectedSimIndex)
        })
            .then(response => {
                if (response.ok) {
                    console.log('Successfully deleted!');

                } else {
                    console.log('Something went wrong when trying to delete!');
                }
            })
            .catch(error => {
                console.error('Network error:', error);
            });
    };


    const sortedSimulations = simulations.slice().sort((a, b) => a.name.localeCompare(b.name));

    return (
        <div>

            <h1 style={{ marginBottom: '40px' }}>Delete simulations: </h1>

            <div style={{ border: "1.5px solid #fff", borderRadius: "6px", padding: "25px 25px 25px 35px " }}>
                <p>Select simulations to delete: <br></br><span style={{ fontSize: '13px' }}>(simulations appear in alphabetical order)</span></p>
              <Row>
                    <ListGroup style={{ maxHeight: "500px", overflowY: "auto" }}>
                    {sortedSimulations.map((simulation, index) => (
                        <ListGroup.Item key={index} style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                            <div style={{ overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: "600px" }}>{simulation.name}</div>

                            <div className="text-end">
                                <FontAwesomeIcon
                                    icon={faTimes}
                                    style={{ color: 'red', cursor: 'pointer' }}
                                    onClick={() => {
                                        setSelectedSimulation(simulation);
                                        setModalShow(true); 
                                    }}
                                  />
                            </div>
                        </ListGroup.Item>))}
                </ListGroup>
              </Row>
                <DeleteSimMessage
                    show={modalShow}
                    onHide={() => setModalShow(false)}
                    onDelete={() => {
                        handleDelete(selectedSimulation);
                        setModalShow(false);
                    }}                    simname={selectedSimulation}              />

            </div>

        </div>

        
    );
}

export default DeleteStep1;
