import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

const WelcomeModal = (props) => {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      style={{
        color: "black",
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          RCR Simulation lab
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4>Welcome!</h4>
        <p><br></br>
                  You will be assigned new roles during the simulation,<br></br>
                  and information about your role will show on this page.<br></br><br></br>
                  When you get a new message please confirm that you have read it by clicking the "I have read this message" - button.

        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
};
export default WelcomeModal;
