import { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Container from "react-bootstrap/Container";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function EditStep1({ onSubmit }) {

    const getInitialState = () => {
        const value = "Choose a saved simulation to use as template";
        return value;
    };
    const [data, setData] = useState([]);
    const [value, setValue] = useState(getInitialState);
    const [formData, setFormData] = useState({});
    const [simName, setSimName] = useState("");

    useEffect(() => {
        fetch('/fetch-simulations')
            .then(response => response.json())
            .then(data => setData(data));
    }, []);

    const handleChangeOption = (e) => {
        const selectedValue = e.target;
        const simid = selectedValue.options[selectedValue.selectedIndex].value;
        const simname = selectedValue.options[selectedValue.selectedIndex].text;
        setSimName(simname);
        setValue(simid);
    };

    function handleSubmit(event) {
        event.preventDefault();
        onSubmit(formData);
    }

    function handleInputChange(event) {
        setFormData({
            ...formData,
            [event.target.name]: event.target.value,
            simid: value,
            simname: simName
        });
    }

    const contentStyle = {
    
        borderColor: '#4C6C84',
        color: '#fff',
        padding: '12px 24px',
        borderRadius: '0px 0px  8px  8px',
        paddingTop: '20px',
        fontSize: '16px',
        overflow: 'auto'
    
    }

    return (
        <div>
            <Row>
                <h1 style={{ textAlign: 'center', marginBottom: '40px' }}>Create a new simulation <br></br><span><h2>with an existing simulation as a template</h2></span></h1>
            </Row>
            <div style={{ border: "1.5px solid #fff", borderRadius: "6px" }}>
            <Row>
                <ul className="steps">
                    <li className="is-active">1: Template & name</li>
                    <li>2: Environments</li>
                    <li>3: Roles</li>
                    <li>4: Connect roles to environments</li>
                    <li>5: Save simulation</li>
                </ul>
            </Row>
          

            <Row>
                <div className="choose-simulation-container">
                    <label id="choose-simulation-label">Choose an existing simulation to use as template:</label>
                        <select style={{ width: "95%"}} id="choose-simulation" onChange={handleChangeOption}>
                        <option >Choose an existing simulation</option>
                        {data.map((entity, index) => (
                            <option key={index} value={entity.idSimulation}>{entity.name}</option>
                        ))}

                    </select>
                </div>
            </Row>

            <Row>
              <form onSubmit={handleSubmit} style={contentStyle} >
                <Container>
                    <Row>
                            <label>Give a name to the simulation: <br></br><span style={{ fontSize: '14px' }}>(Note! Don't give the new simulation the same name as the template you're using)</span> </label>
                            <input className="name-input" type="text" name="name" onChange={handleInputChange} simid={value} simname={simName} required />
                    </Row>

                    <Row>
                            <Col></Col>
                            <Col className="text-end" style={{ marginTop: '30px' }}> <Button type="submit">Next step{' >'} </Button></Col>
                    </Row>
                </Container>
              </form>
            </Row>
            </div>
            
        </div>

    );
}

export default EditStep1;
