import React, { useState } from "react";
import Button from 'react-bootstrap/Button';
import Form from "react-bootstrap/Form";

function Authenticationform({ onAuthenticate }) {
    const [code, setCode] = useState("");
    const [error, setError] = useState("");

    const handleSubmit = async (event) => {
        event.preventDefault();

        try {
            await onAuthenticate(code);
        } catch (error) {
            setError("Invalid code. Please try again.");
        }
        setCode("");
        setTimeout(() => {
            setError("");
        }, 3000);
    };

    return (
        <Form onSubmit={handleSubmit}>
            <Form.Group controlId="code">
                <Form.Label style={{ fontWeight: "bold", fontSize: "1.2rem" }}>Enter the password (4 digits):</Form.Label>
                <Form.Control
                    type="text"
                    value={code}
                    onChange={(e) => setCode(e.target.value)}
                />
            </Form.Group>

            <Button variant="primary" type="submit" className="mt-3">
                Authenticate
            </Button>

            {error && <div className="mt-3 text-danger">{error}</div>}
           
        </Form>
    );
}

export default Authenticationform;






