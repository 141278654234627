import React from 'react';
import Participants from "./components/participants/ParticipantsContainer";
import RunSimulation from "./components/runsimulation/ConnectedUsers";
import PrepareSimulation from "./components/preparesimulation/PrepareSimulation";

const AppRoutes = [
    {
        index: true,
        element: <RunSimulation />
    },
    {
        path: '/prepare-simulation',
        element: <PrepareSimulation />
    },
    {
        path: '/participants',
        requireAuth: false,
        element: <Participants />
    }
];

export default AppRoutes;