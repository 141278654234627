import React, { useEffect, useState } from 'react';
import Btn from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

const TruncateParticipants = ({ onTruncante }) => {
    const [isTruncated, setIsTruncated] = useState(false);
    const [error, setError] = useState(null);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    function refreshPage() {
        window.location.reload();
    }

    const handleTruncate = async () => {
        try {
            const response = await fetch('/truncate-participants', {
                method: 'POST',
            });

            if (response.ok) {
                setIsTruncated(true);
                setError(null);
                handleClose();
                onTruncante();
                refreshPage();
            } else {
                setIsTruncated(false);
                setError('Failed to truncate Participants table.');
            }
        } catch (error) {
            console.error('An error occurred:', error);
            setIsTruncated(false);
            setError('An error occurred while truncating Participants table.');
        }
    };


    return (
        <div>
            
            <Btn variant="danger" onClick={handleShow} >Stop running the simulation</Btn>
            {error && <p>{error}</p>}

            <Modal show={show} onHide={handleClose}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                style={{
                    color: "black",
                }}>
                <Modal.Header closeButton>
                    <Modal.Title>Stop running the simulation</Modal.Title>
                </Modal.Header>
                <Modal.Body>All connected participants will be disconnected and won't be able to recive messages until they reconnect!</Modal.Body>
                <Modal.Footer>
                    <Btn variant="secondary" onClick={handleClose}>
                        Close
                    </Btn>
                    <Btn variant="danger" onClick={handleTruncate}>
                        Stop running the simulation
                    </Btn>
                </Modal.Footer>
            </Modal>

        </div>
    );
};

export default TruncateParticipants;
