import React, { useState } from "react";
import Step1 from "./FromExistingStep1";
import Step2 from "./FromExistingStep2";
import Step3 from "./FromExistingStep3";
import Step4 from './FromExistingStep4';

function MultiStepEditSimulation() {
    const [step, setStep] = useState(1);
    const [stepOneData, setStepOneData] = useState({});
    const [stepTwoData, setStepTwoData] = useState({});
    const [stepThreeData, setStepThreeData] = useState({});
    const [stepFourData, setStepFourData] = useState([]);

    function handleClickNewSimulation() {
        setStep(1)
    }

    function handleStepOneSubmit(data) {
        setStepOneData(data);
        setStepTwoData("");
        setStepThreeData("");
        setStep(2);
    }

    function handleStepTwoSubmit(data) {
        setStepTwoData(data);
        setStep(3);
    }

    function handleStepThreeSubmit(data) {
        setStepThreeData(data);
        setStep(4);
    }

    function handleStepThreeAdd(data) {
        setStepThreeData(data);
    }

    const handleStepFourSubmit = (items) => {
        setStepFourData(items);
    };

    function handleGoBack() {
        setStep(step - 1);
    }

    function handleGoToStart() {
        setStep(step - 4);
    }


    function handleSubmit() {
        const url = '/Step4';

        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(stepFourData)
        })
            .then(response => {
                if (response.ok) {
                    console.log('Data was successfully posted!');

                } else {
                    console.log('Something went wrong!');
                }
            })
            .catch(error => {
                console.error('Network error:', error);
            });
    };


    const steps = [
        {
            component: <div className="prepare-simulation-box" onClick={handleClickNewSimulation}>Prepare a new simulation with an existing simulation as a template</div>, title: 'Step 0'
        },
        {
            component: <Step1 onSubmit={handleStepOneSubmit} />, title: 'Step 1'
        },
        {
            component: <Step2 prevStep={handleGoBack} onSubmit={handleStepTwoSubmit} simulation={stepOneData} scenarios={stepTwoData} />, title: 'Step 2'
        },
        {
            component: <Step3 prevStep={handleGoBack} onSubmit={handleStepThreeSubmit} addRole={handleStepThreeAdd} simulation={stepOneData} roles={stepThreeData} scenarios={stepTwoData} />, title: 'Step 3'
        },
        {
            component: <Step4 prevStep={handleGoBack} onSaveItems={handleStepFourSubmit} scenarios={stepTwoData} roles={stepThreeData}
                simulation={stepOneData} onSubmit={handleSubmit}
            />, title: 'Step 4'
        },
       
    ];

    const containerStyle = {
        Width: '85%',
        margin: '10%',
        borderRadius: '8px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',

    };

    return (
        <div style={containerStyle} >

            {steps[step - 0].component}

        </div>
    );
}

export default MultiStepEditSimulation;

