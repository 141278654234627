import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import React, { useState } from "react";
import { NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';

const SaveSimModal = (props) => {

    const [isSaved, setIsSaved] = useState(false);

    const handleSave = () => {
        setIsSaved(true);
        props.onSave();
    };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      style={{
        color: "black",
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
                  {isSaved ? "Your simulation has been saved!" : "Do you want to save the simulation?"}
        </Modal.Title>
      </Modal.Header>
          <Modal.Body style={{textAlign: 'center'} }>
        <h4> {isSaved ? "" : ""}</h4>
        <p>
                  {isSaved ? "The simulation is now ready to run!" : "Press the 'X' if you want to continue editing the simulation"}
         </p>
              {isSaved ?(
                  <>
                      <Button  style={{ marginTop: '-10px', marginBottom: '20px' }}>
                          <NavLink tag={Link} className="text-light" to="/">Run the simulation</NavLink>
                      </Button>
                      <p>
                          {isSaved ? "Or click the `X` and then `Cancel` in the upper right corner to prepare additonal simulations." : ""}
                      </p>
                      
                  </>) : null}
              
      </Modal.Body>
      <Modal.Footer>
              {isSaved ? (
                  <>
                     
                  </>
              ) : (
                  <Button onClick={handleSave}>Yes, I want to save the simulation!</Button>
              )}
      </Modal.Footer>
    </Modal>
  );
};

export default SaveSimModal;
