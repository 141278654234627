import { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function Step3({ onSubmit, simulation, roles, addRole, prevStep }) {
    const [formData, setFormData] = useState([{ description: '' }]);
    const [listData, setListData] = useState([{ description: '' }]);

    useEffect(() => {
        if (Array.isArray(roles)) {
            setFormData(roles);
            setListData(roles);
        }
    }, []);

    function handleSubmit(event) {
        event.preventDefault();
        onSubmit(formData);
        setListData(formData);
    }

    function handleInputChange(event, index) {
        const { name, value } = event.target;
        const updatedFormData = [...formData];
        updatedFormData[index] = {
            ...updatedFormData[index],
            [name]: value
        };
        setFormData(updatedFormData);
    }

    function handleAdd() {
        setFormData([...formData, { description: '' }]);
       
        const newArray = formData;
        newArray.push('');
        addRole(newArray);
        setListData(formData);
    }

    const contentStyle = {
        display: 'block',
        width: 'auto',
        boxSizing: 'border-box',
        float: 'left',
        margin: '0 16px'

    }

    const removeItem = (index) => {
        if (formData.length > 1) {
            setFormData(formData.filter((o, i) => index !== i));
            setListData(listData.filter((o, i) => index !== i));
        }
    };

    return (
        <div>
      
            <Row>
                <h1 style={{ textAlign: 'center', marginBottom: '40px' }}>You are creating the simulation: {simulation.name}</h1>
               
            </Row>
            <div style={{ border: "1.5px solid #fff", borderRadius: "6px" }}>

            <ul className="steps">
                <li>1: Name</li>
                <li>2: Environments</li>
                <li className="is-active">3: Roles</li>
                <li>4: Connect roles to environments</li>
                <li>5: Save simulation</li>
            </ul>
                <Row style={{ margin: "auto", display: "flex", justifyContent: "center" }}>
                <form onSubmit={handleSubmit} style={contentStyle} className="inputRoles">
                    {formData.length > 0 && (
                        <div>
                            <label>
                                Add roles/messages:<br></br>
                                    <textarea className="role-txt-area" style={{ height: "100px", borderRadius: '5px' }} type="text" name="description" value={formData[formData.length - 1].description} onChange={(event) => handleInputChange(event, formData.length - 1)} />
                            </label>
                        </div>
                    )}
                    <Button type="button" style={{ marginLeft: 'auto', marginRight: 'auto' }} onClick={handleAdd}>+</Button>
                </form>
            </Row>

            <Row>
                <div style={{ maxHeight: "240px", overflowY: "auto", marginTop: '35px' }}>
                    <p style={{ fontWeight: 'bold' }}>Added roles ({formData.length - 1}):</p>
                    <ul style={{ wordWrap: "break-word", listStyle: 'none' }}>
                        {listData.map((data, index) => (
                            <li key={index}>{data.description}  {index !== formData.length - 1 && (<span className="role-delete" onClick={() => removeItem(index)}> X</span>)}<hr />
                            </li>

                        ))}
                    </ul>
                </div>
            </Row>
                <Row style={{ marginTop: '30px', padding: "20px" }}>

                <Col className="text-start">
                    <Button onClick={prevStep} >{' <'} Previous step</Button>
                </Col>
                <Col className="text-end">
                    <form onSubmit={handleSubmit}>
                        <Button type="submit">Next step{' >'}</Button>
                    </form>
                </Col>

            </Row>
           
           </div>

        </div>

    );
}

export default Step3;
