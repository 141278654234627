import React, { useState } from "react";
import SaveSimMessage from "../SaveSimModal";
import Button from 'react-bootstrap/Button';
import Container from "react-bootstrap/Container";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function EditStep4({ scenarios, roles, onSaveItems, simulation, onSubmit, prevStep }) {


    const [number, setNumber] = useState(1);
    const [open, setOpen] = useState(true);
    const [shown, setShown] = useState(false);
    const [modalShow, setModalShow] = useState(false);
    const [isScenarioSaved, setIsScenarioSaved] = useState(false);
    const [selectedScenario, setSelectedScenario] = useState(null);
    const [selectedRoles, setSelectedRoles] = useState([]);
    const [savedSelectedItems, setSavedSelectedItems] = useState([]);
    const [openStates, setOpenStates] = useState(savedSelectedItems.map(() => false));

    function numberCount(scenarios) {
        setNumber(number + 1)

        if (number >= scenarios.length - 1) {
            setOpen(false);
        }
        else {
            setOpen(true);
        }
    }

    const toggle = (index) => {
        const newOpenStates = [...openStates];
        newOpenStates[index] = !newOpenStates[index];
        setOpenStates(newOpenStates);

    };

    const handleScenarioClick = (scenario) => {
        setSelectedScenario(scenario);
    };

    const handleRoleClick = (role) => {
        if (selectedRoles.includes(role)) {
            setSelectedRoles(selectedRoles.filter((selectedRole) => selectedRole !== role));
        } else {
            setSelectedRoles([...selectedRoles, role]);
        }
    };

    const handleSaveClick = () => {
        const selectedItems = [...selectedRoles];

        if (selectedScenario) {
            selectedItems.unshift(selectedScenario);
        }

        const newSavedItem = {
            name: simulation.name,
            scenario: selectedScenario?.name,
            roleDescriptions: selectedRoles.map((role) => role.description),
        };

        setSavedSelectedItems((prevSelectedItems) => [...prevSelectedItems, newSavedItem]);
        setSelectedScenario(null);
        setSelectedRoles([]);
        setShown(true);
        setIsScenarioSaved(true);
    };

    const handleSubmit = () => {
        onSaveItems(savedSelectedItems);
        setModalShow(true);
    };

    return (
        <div>
            <Row>
                <h1 style={{ marginBottom: '40px' }}>You are creating the simulation: {simulation.name} <span><h4>using the existing simulation: {simulation.simname} as template</h4></span></h1></Row>
            <div style={{ border: "1.5px solid #fff", borderRadius: "6px", padding: "15px 25px 20px 20px " }}>
            <Row>
                <ul className="steps" style={{ marginBottom: '45px' }}>
                    <li>1: Template & name</li>
                    <li>2: Environment</li>
                    <li>3: Roles</li>
                    <li className={open ? 'is-active' : ''}>4: Connect roles to environments</li>
                    <li className={open ? '' : 'is-active'}>5: Save simulation</li>
                </ul>
            </Row>

            <Row>
                    {open && (<div className="number-count">

        <Container style={{ padding: "20px 0" }}>
            <Row>
                 <Col xs={4}>
                                <h4>Select environment  {number} <br></br> in the simulation:</h4>
                        {scenarios.map((scenario, index) => (

                            index !== scenarios.length - 1 && (<div
                                key={index}
                                style={{
                                    padding: "10px",
                                    backgroundColor: scenario === selectedScenario ? "#8fbc8f" : "#f9f9f9",
                                    borderRadius: '6px',
                                    cursor: "pointer",
                                    margin: "10px",
                                    width: "100%",
                                    whiteSpace: "nowrap",
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis'
                                }}
                                onClick={() => handleScenarioClick(scenario)}
                            >
                                {scenario.name}
                            </div>)
                        ))}

                 </Col>

                 <Col style={{ maxWidth: '65%' }} >

                    <div style={{ maxHeight: "540px", overflowY: "auto" }}>
                        <h4>Select roles to be part of <br></br> environment {number}:</h4>
                        {roles.map((role, index) => (
                            index !== roles.length - 1 && (<div
                                key={index}
                                style={{
                                    padding: "10px",
                                    borderRadius: '6px',
                                    width: "94%",
                                    backgroundColor: selectedRoles.includes(role) ? "#8fbc8f" : "#f9f9f9",
                                    cursor: "pointer",
                                    whiteSpace: "nowrap",
                                    overflow: 'hidden',
                                    margin: "10px",
                                    textOverflow: 'ellipsis'
                                }}
                                onClick={() => handleRoleClick(role)}
                            >
                                {role.description}


                            </div>)
                        ))}
                        </div>
                    </Col>
            </Row>
        </Container>


                </div>)}

                {open && (<div>
                        <Button onClick={() => { handleSaveClick(); numberCount(scenarios); }}>Save environment {number}</Button>

                </div>)}

                    {shown && (<div className="role-scenario" style={{ maxHeight: "600px", overflowY: "auto", marginTop: '60px', textAlign: "center" }}>
                    <h4 style={{ textAlign: "center" }}>Overview of the new simulation {simulation.name}:</h4>
                        <ul style={{ wordWrap: "break-word", listStyle: 'none' }}>
                        {savedSelectedItems.map((item, index) => (
                            <li className="scenario-overview" key={index}>
                                Scenario {index + 1}: {item.scenario} <span onClick={() => toggle(index)}> {openStates[index] ? "-" : "+"}</span>

                                {openStates[index] && (<ul>
                                    {item.roleDescriptions.map((description, i) => (
                                        <li style={{ marginBottom: "5px", listStyle: 'none', fontSize: '16px', marginTop: "10px", textAlign: "center" }} className="toggle" key={i}>{description} <hr></hr></li>
                                    ))}

                                </ul>)}

                            </li>
                        ))}
                    </ul>

                </div>)}
            </Row>

                <Row style={{ marginTop: '30px' }}>

                    <Col className="text-start">
                        <Button onClick={prevStep} >{' <'} Previous step</Button>
                    </Col>
                    <Col className="text-end">
                        <Button style={{
                            cursor: isScenarioSaved ? 'pointer' : 'not-allowed',
                            ...(isScenarioSaved ? {} : { backgroundColor: 'grey', boxShadow: 'none' }),
                        }}
                            onClick={handleSubmit} disabled={!isScenarioSaved}>Save simulation</Button>
                    </Col>
                </Row>

                <SaveSimMessage
                    show={modalShow}
                    onHide={() => setModalShow(false)}
                    onSave={onSubmit} />

            </div>
        </div>
    );

}

export default EditStep4;









