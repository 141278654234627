import React, { useState } from "react";
import Button from 'react-bootstrap/Button';
import Form from "react-bootstrap/Form";

function Authenticationform({ onAuthenticate }) {
    const [userName, setUserName] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");

    const handleSubmit = async (event) => {
        event.preventDefault();

        try {
            
            await onAuthenticate(userName, password);
            window.location.reload()
        } catch (error) {
            setError("Wrong username or password. Please try again.");
        }
        setUserName("");
        setPassword("");
        setTimeout(() => {
            setError("");
        }, 3000);
    };

    return (
        <Form onSubmit={handleSubmit}>
            <Form.Label style={{ fontWeight: "bold", fontSize: "1.2rem" }}>Enter username and password:</Form.Label>
            <Form.Group controlId="userName">
                <Form.Control
                    placeholder="Username"
                    type="text"
                    value={userName}
                    onChange={(e) => setUserName(e.target.value)}
                />
            </Form.Group>

            <Form.Group controlId="password">
                <Form.Control
                    type="password" 
                    placeholder="Password" 
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                />
            </Form.Group>

            <Button variant="primary" type="submit" className="mt-3">
                Login
            </Button>

            {error && <div className="mt-3 text-danger">{error}</div>}
        </Form>
    );
}

export default Authenticationform;






