import React, { Component } from "react";
import {
  Navbar,
  NavbarBrand,
} from "reactstrap";
import "./NavMenu.css";

export class NavMenuParticipants extends Component {
  static displayName = NavMenuParticipants.name;

  constructor(props) {
    super(props);}

  render() {
    return (
      <header>
        <Navbar
          className="navbar-expand-sm navbar-toggleable-sm box-shadow mb-3"
          container
          light>
                <NavbarBrand > RCR Simulation Lab</NavbarBrand>
        </Navbar>
      </header>
    );
  }
}
