import React, { useState } from "react";
import EditScenarioRoles from "./EditScenarioAndRoles";
import EditConnections from './EditConnections';


function MultiStepModifySimulation() {
    const [step, setStep] = useState(1);
    const [stepOneData, setStepOneData] = useState({});

    function handleClickNewSimulation() {
        setStep(1)
    }

    function handleStepOneSubmit(data) {
        setStepOneData(data);
       setStep(2);
    }

    //Method to go back to first step
    //function handleGoBack() {
    //    setStep(step - 1);
    //}

    const steps = [
        {
            component: <div className="prepare-simulation-box" onClick={handleClickNewSimulation}>Edit an existing simulation</div>, title: 'Step 0'
        },
        {
            component: <EditScenarioRoles onSubmit={handleStepOneSubmit} />, title: 'Edit environments and roles'
        },
        {
            component: <EditConnections 
              initialData={stepOneData}
            />, title: 'EditConnections'
        },

    ];

    const containerStyle = {
        Width: '85%',
        margin: '10%',
        borderRadius: '8px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
    };

    return (
       


        <div style={containerStyle} >
            {steps[step - 0].component}
                            </div>
            
        
    );
}

export default MultiStepModifySimulation;

