import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import React, { useState } from "react";

const DeleteSimModal = (props) => {

    const [isSaved, setIsSaved] = useState(false);

    const handleSave = () => {
        setIsSaved(true);
        props.onDelete();
    };

   
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      style={{
        color: "black",
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
                  Do you want to delete the simulation: "{props.simname ? props.simname.name : 'selected'}"?
        </Modal.Title>
      </Modal.Header>
          <Modal.Body style={{textAlign: 'center'} }>
        <p>
                  <Button onClick={handleSave}>Yes, I want to delete the simulation: "{props.simname ? props.simname.name : 'selected'}"!</Button>
        </p>
              
      </Modal.Body>
      
    </Modal>
  );
};

export default DeleteSimModal;
