import React, { useState, useEffect } from "react";
import PrepareNewSimulation from './preparenewsimulation/PrepareNewSimulation';
import PrepareExistingSimulation from './preparesimulationfromtemplate/PrepareFromExistingSimulation';
import EditSimulation from './editsimulations/EditSimulation';
import DeleteSimulation from "./deletesimulations/DeleteSimulation";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Authentication from './Authenticationform.js';



function PrepareSimulation() {
    const [step, setStep] = useState(0);
    const [buttonshow, setbuttonshow] = useState(true);

    const steps = [

        {
            component: <div></div>, title: 'Step 0'
        },
        {
            component: <PrepareNewSimulation step0={handleGoToStart} />, title: 'Step 1'
        },
        {
            component: <PrepareExistingSimulation />, title: 'Step 2'
        },
        {
            component: <EditSimulation />, title: 'Step 3'
        },
        {
            component: <DeleteSimulation />, title: 'Step 3'
        }
    ];

    function handleGoToStart() {
        setStep(0);
        setbuttonshow(true);
    }

    function handleClickNewSimulation() {
        setStep(1)
        setbuttonshow(false);
    }

    function handleClickNewSimulationFromExisting() {
        setStep(2)
        setbuttonshow(false);
    }

    function handleClickEditSimulation() {
        setStep(3)
        setbuttonshow(false);
    }

    function handleClickDeleteSimulation() {
        setStep(4)
        setbuttonshow(false);
    }

    const [authenticated, setAuthenticated] = useState(null);

    useEffect(() => {
        checkPassword();
    }, []);

    //Used when the user refreshes the page
    const checkPassword = async () => {
        try {
            const storedData = localStorage.getItem("personnelCredentials");
            const isAuthenticated = JSON.parse(storedData);

            if (!isAuthenticated) {
                setAuthenticated(false);
                return;
            }
            const url = '/authenticate-personnel';
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(isAuthenticated)
            });

            if (response.ok) {
                setAuthenticated(true);
            }
        }
        catch (error) {
        }
    };



    const authenticate = async (userName, password) => {

        const url = '/authenticate-personnel';

        const postData = {
            Name: userName,
            Password: password
        };
        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(postData)
            });

            if (response.ok) {
                setAuthenticated(true);
                localStorage.setItem("personnelCredentials", JSON.stringify(postData));

            }
            else {
                throw new Error("Authentication failed");
            }
        } catch (error) {
            throw error;
        }
    };


    return (
        <div>


            {authenticated === null ? (
                <div></div>

                //<div className="d-flex justify-content-center align-items-center vh-100" style={{ fontWeight:'1000', fontSize:'30px' } }>Loading...</div>

            ) : !authenticated ? (
                <Authentication onAuthenticate={authenticate} />
            ) : (
                <>

            {step > 0 && <Row>
                <Col className="text-end">
                     
                    <OverlayTrigger
                        placement="left"
                        overlay={<Tooltip id="container-tooltip">Click to cancel and go back to options of
                            how you want to prepare/configure simulations.
                            Any changes or information you have written will NOT be saved!</Tooltip>}
                    >
                    <button type="button" onClick={handleGoToStart} className="btn btn-danger">Cancel</button>
                    </OverlayTrigger>
                    
                </Col>
            </Row>}
            
            {steps[step - 0].component}
    
            {buttonshow && (
                <Row xs={1} md={2} className="g-4" style={{margin:"8% 0" } }>

                <Col>
                        <Card className="prepareCard" style={{ backgroundColor: "#00759E", cursor: "pointer", height: "100%" }} onClick={handleClickNewSimulation}>
                       
                        <Card.Body>
                            <Card.Title>Prepare new</Card.Title>
                            <Card.Text>
                                Prepare a new simulation from scratch.
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
                <Col>
                        <Card className="prepareCard" style={{ backgroundColor: "#00759E", cursor: "pointer", height: "100%" }} onClick={handleClickNewSimulationFromExisting}>
                     
                        <Card.Body>
                            <Card.Title>Prepare with template</Card.Title>
                            <Card.Text>
                                Prepare a new simulation with an existing simulation as a template.
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
                <Col>
                        <Card className="prepareCard" style={{ backgroundColor: "#00759E", cursor: "pointer", height: "100%" }} onClick={handleClickEditSimulation} >
                       
                        <Card.Body>
                            <Card.Title>Edit</Card.Title>
                            <Card.Text>
                                Edit an existing simulation.
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
                <Col>
                        <Card className="prepareCard" style={{ backgroundColor: "#00759E", cursor: 'pointer', height: "100%" }} onClick={handleClickDeleteSimulation}>
                     
                        <Card.Body>
                            <Card.Title>Delete</Card.Title>
                            <Card.Text>
                                Delete one or more simulations.
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>

            </Row>
            )}
                </>
            )}
        </div>

    );

}

export default PrepareSimulation;

